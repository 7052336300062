import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Seznam = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #800202, #B41818, #CF0606, #B41818, #800202 )",
        icons: "#ca0001",
        navClass: "seznam",
      }}
      seo={{
        title: "Seznam",
        headerTitle: "seznam",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/seznam_top.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/seznam/",
      }}
    >
      <PortfolioHeader name="seznam" height="82" />
      <section className="container-fluid seznam_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Seznam.cz</h1>
              <ul>
                <li>International Marketing Campaigns</li>
                <li>Case Study</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                When the Cllient came to us with a question about the offer of
                Google Ads (AdWords) campaigns in the Czech Republic, we were
                very pleased, as we like to expand the scope of our activities
                and conquer the foreign markets. We did not think, however, how
                much this case would bring to our work and in how interesting
                way it would allow for us to expand our knowledge.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid seznam_section_3">
        <div className="row">
          <div className="col-md-8 m-auto">
            <h4>Our Client needs</h4>
            <p>
              The owner of a large online store with an interior design
              assortment asked us to audit the current Google Ads (AdWords)
              campaign and to prepare a promotion strategy for their e-commerce
              business.
            </p>
            <p>
              Our audit showed many shortcomings in both the structure and
              operation of the current campaign. A product campaign important
              for e-commerce was missing, and campaigns targeting the search
              network were not well refined.
            </p>
            <p>
              After reviewing the profile of the Czech Client, analyzing his
              needs and after understanding the architecture of the online
              store, and the offered assortment, we came up with a proposal to
              completely rebuild the campaign and created a work plan for the
              next three months.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <h4>Obstacles:</h4>
            <p>
              At first glance, the most difficult task seemed to be to create
              text ads in Czech in such a way that they actually correspond to
              the user's query and information on the Landing Page. Creating
              text ads, we supported the ads from the previous campaign, which
              required technical and other small corrections. However, they were
              a good starting point for current, properly constructed ads.
            </p>

            <p>
              Analysis of the first results showed that despite the use of
              different types of phrase matching, ads are displayed to users
              looking for products similar to those offered by the store.
              Observation of search entries made by users in Czech was not an
              easy task - despite many similarities with the Polish language,
              the vast majority of words have a different meaning. Excluding
              irrelevant keywords was one of the basic elements of properly
              optimizing this campaign.
            </p>

            <p>
              Another obstacle that we had in mind before was the fact that a
              large number of Czech Internet users are still using the native
              search engine - Seznam.cz. According to Digital News Report, 35%
              of Czech Internet users are loyal to Seznam [i]. The combination
              of activites on both Google and Seznam gave us the opportunity to
              reach 90% of users on the Czech market.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <figure className="figure">
              <img
                src={require("../../../assets/img/portfolio/seznam_main_1.jpg")}
                className="figure-img img-fluid"
                alt=""
              />
              <figcaption className="figure-caption mt-3">
                Ryc. 1 Seznam.cz – jak widać jest nie tylko wyszukiwarką.
              </figcaption>
            </figure>
            <p className="my-5">
              Despite Google slowly entering the Czech market, it is mainly
              young people who like it. Our main target group were people aged
              30+, and they are still faithful to the traditions, and are more
              likely to use Seznam. Seznam, like Google, is not just a search
              engine. It gives users many options: creating email accounts,
              reviewing messages, using maps, and also allows advertisers to
              place sponsored links and banners in the content network, as well
              as set up remarketing and product campaigns using the Sklik
              marketing tool. Sklik is a system that works similarly to Google
              Ads (AdWords), but unfortunately, compared to Google Ads, it does
              not have many basic functions to facilitate the work of creating
              ads (such as the ability to copy campaigns or ad groups).
            </p>
            <figure className="figure">
              <img
                src={require("../../../assets/img/portfolio/seznam_main_2.jpg")}
                className="figure-img img-fluid"
                alt=""
              />
              <figcaption className="figure-caption mt-3">
                Advertisements on Seznam. Compared to the current Google search
                results, Seznam is heavily ad-supported and continues to support
                ads in the right column. For people accustomed to the style of
                Google, Seznam may seem a bit chaotic and disordered.
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <h4>Solution:</h4>
            <p>
              The main goal of the campaign, as is usually the case with
              e-commerce campaigns, was to increase revenue of the store.
              However, for SEM campaigns to bring the expected results, it was
              necessary to build a new Google Ads campaign (AdWords) and become
              familiar with the Sklik system and running the campaign on Seznam.
            </p>
            <p>
              In our activities, we focused primarily on product campaigns that
              were not there before and that required configuration from
              scratch: generating a product file, creating Google Merchant
              Center accounts, and creating campaigns in Google Ads and Sklik.
            </p>
            <p>
              Current work on the campaign includes optimization of bids, ads
              and, above all, actual user queries that require the use of
              multiple keyword exclusions.
            </p>

            <h4>Results of our work:</h4>
            <p>
              Our Agency has been looking after this client's campaign for only
              a month. Despite this, we could have already drawn conclusions and
              observed interesting results. Allocating larger advertising
              budgets to a product campaign, which became the main source of
              revenue from the campaign, was a very good move. There was a
              noticeable difference in the results of campaigns on Google and on
              Seznam - definitely product campaigns are better perceived by
              users using Google search engine. This may be related to the
              appearance of the search results page displaying product ads. In
              the Google Ads panel, we also see how traffic from optimized text
              ads has improved and their CTR has also increased.
            </p>

            <h4>Results in numbers:</h4>

            <TrackVisibility once offset={300}>
              {({ isVisible }) => (
                <ul>
                  <li>
                    Revenue increase by{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 74 : 0} />%
                  </li>
                  <li>
                    Amount of transactions increased by{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 62 : 0} />%
                  </li>
                  <li>
                    Increase of e-commerce ratio by{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 25 : 0} />%
                  </li>
                  <li>
                    Increase of average order value by{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 7 : 0} />%
                  </li>
                  <li>
                    Increase of average amount of products in a single order of{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 74 : 0} />%
                  </li>
                </ul>
              )}
            </TrackVisibility>
            <figure className="figure">
              <img
                src={require("../../../assets/img/portfolio/seznam_main_3.jpg")}
                className="figure-img img-fluid"
                alt=""
              />
              <figcaption className="figure-caption mt-3">
                Screenshot from the Google Analytics panel - comparison of
                e-commerce conversions for the period of our activities and the
                period immediately preceding them (includes traffic from Google
                and Seznam).
              </figcaption>
            </figure>

            <h4>What’s next:</h4>
            <p>
              We do not stop our efforts and plan to further develop our
              Client's campaign. To keep sales at such a high (and even higher)
              levels, we plan to optimize the current landing page. Support in
              the coming months will include help in entering the store markets
              in other countries and preparation of guidelines for building a
              new e-commerce site.
            </p>
            <p>
              For When there are no impossible tasks, and entering a different
              marketing system, and getting to know its interface and
              functioning from scratch was a really intriguing experience for
              us. We love such challenges!
            </p>
            <p>
              The presented case study regards a marketing project ran by the
              WHEN Digital Marketing Agency - a part of the ADream Group
            </p>
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Seznam;
